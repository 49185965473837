import React, { ReactElement } from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactComponent as LogoVertical } from '@/assets/logo-full-vertical.svg';
import { ReactComponent as InstagramLogo } from '@/assets/social-icons/instagram.svg';
import { ReactComponent as FacebookLogo } from '@/assets/social-icons/facebook.svg';
import privacyPolicy from '@/assets/kota-privacy.pdf';
import projects from '@/assets/program_ruralnog_razvoja.pdf';

export default function Footer(): ReactElement {
  const { t } = useTranslation();

  return (
    <footer className="flex bg-gray-dark py-12">
      <div className="flex flex-wrap mx-auto w-full max-w-screen-lg text-gray-lighter body-text justify-center">
        <div className="w-2/3 md:w-1/3 max-w-md text-gold-default px-16 border-white md:border-r mb-12 md:mb-0">
          <LogoVertical />
        </div>
        <div className="w-full md:w-1/3 px-16 text-center md:text-left border-white md:border-r mb-12 md:mb-0">
          Bovan d.o.o.
          <br />
          Nadin 104A
          <br />
          23223 Škabrnja, Zadar
          <br />
          Republika Hrvatska
          <br />
          OIB: 22664930073
          <br />
          <br />
          <br />
          <a
            href={privacyPolicy}
            rel="noreferrer"
            target="_blank"
            className="block font-bold hover:text-white hover:underline"
          >
            {t('footer.privacy_policy')}
          </a>
          <a
            href={projects}
            rel="noreferrer"
            target="_blank"
            className="block font-bold hover:text-white hover:underline"
          >
            {t('footer.projects')}
          </a>
        </div>
        <div className="flex flex-col w-full md:w-1/3 px-16 text-center md:text-left">
          <h1 className="uppercase font-serif text-3xl mb-6">
            {t('footer.contact')}:
          </h1>
          <a
            className="font-bold hover:text-white hover:underline py-2"
            href="tel:+385958081583"
          >
            (+385) 95 808 1583
          </a>
          <a
            className="font-bold hover:text-white hover:underline py-2"
            href="mailto:info@kota-olive.com"
          >
            info@kota-olive.com
          </a>
          <br />
          <div className="flex justify-center md:justify-start mt-5 gap-x-5">
            <a
              href="https://www.instagram.com/kota_olive/"
              rel="noreferrer"
              target="_blank"
              className="transition text-white hover:text-gold-default"
            >
              <InstagramLogo style={{ height: '2em' }} />
            </a>
            <a
              href="https://www.facebook.com/kotaoliveoil"
              rel="noreferrer"
              target="_blank"
              className="transition text-white hover:text-gold-default"
            >
              <FacebookLogo style={{ height: '2em' }} />
            </a>
          </div>
          <br />
        </div>
      </div>
    </footer>
  );
}
