import React from 'react';

import { Helmet, HelmetProps } from 'react-helmet';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { BaseComponentProps } from '@/types';

import { bodyClass } from './Head.module.css';

type HeadProps = BaseComponentProps & HelmetProps;

const Head: React.FC<HeadProps> = ({ children, title, ...helmetProps }) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  return (
    <Helmet
      bodyAttributes={{
        class: `bg-gray-dark min-h-screen w-full overflow-x-hidden relative ${bodyClass}`,
      }}
      defer={false}
      title={title || t('kota_olive_oil')}
      htmlAttributes={{ lang: language }}
      {...helmetProps}
    >
      {children}
    </Helmet>
  );
};

export default Head;
