import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { BgImageQuery } from '@/../graphql-types';
import { bgImageWrap, bgTop, bgMain } from './BgImage.module.css';

const bgImageQuery = graphql`
  query BgImage {
    main: file(relativePath: { eq: "background.jpg" }) {
      childImageSharp {
        fixed_768: fixed(width: 768, quality: 85) {
          width
          srcWebp
          src
        }
      }
    }
    top: file(relativePath: { eq: "background-top-cut.png" }) {
      childImageSharp {
        fixed_768: fixed(width: 768, quality: 85) {
          width
          srcWebp
          src
        }
      }
    }
    main: file(relativePath: { eq: "background.jpg" }) {
      childImageSharp {
        fixed_1024: fixed(width: 1024, quality: 85) {
          width
          srcWebp
          src
        }
      }
    }
    top: file(relativePath: { eq: "background-top-cut.png" }) {
      childImageSharp {
        fixed_1024: fixed(width: 1024, quality: 85) {
          width
          srcWebp
          src
        }
      }
    }
    main: file(relativePath: { eq: "background.jpg" }) {
      childImageSharp {
        fixed_1280: fixed(width: 1280, quality: 85) {
          width
          srcWebp
          src
        }
      }
    }
    top: file(relativePath: { eq: "background-top-cut.png" }) {
      childImageSharp {
        fixed_1280: fixed(width: 1280, quality: 85) {
          width
          srcWebp
          src
        }
      }
    }
    main: file(relativePath: { eq: "background.jpg" }) {
      childImageSharp {
        fixed_1400: fixed(width: 1400, quality: 85) {
          width
          srcWebp
          src
        }
      }
    }
    top: file(relativePath: { eq: "background-top-cut.png" }) {
      childImageSharp {
        fixed_1400: fixed(width: 1400, quality: 85) {
          width
          srcWebp
          src
        }
      }
    }
  }
`;

const BgImage: React.FC = () => {
  const data = useStaticQuery<BgImageQuery>(bgImageQuery);
  const main = data.main.childImageSharp;
  const top = data.top.childImageSharp;

  const imageVars = `
    :root {
      --bg-image-top-768: url('${top.fixed_768.srcWebp}');
      --bg-image-top-1024: url('${top.fixed_1024.srcWebp}');
      --bg-image-top-1280: url('${top.fixed_1280.srcWebp}');
      --bg-image-top-1400: url('${top.fixed_1400.srcWebp}');

      --bg-image-main-768: url('${main.fixed_768.srcWebp}');
      --bg-image-main-1024: url('${main.fixed_1024.srcWebp}');
      --bg-image-main-1280: url('${main.fixed_1280.srcWebp}');
      --bg-image-main-1400: url('${main.fixed_1400.srcWebp}');
    }
  `;

  return (
    <>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: imageVars }}
      />
      <div
        className={`absolute top-0 left-0 right-0 bottom-0 -z-1 ${bgImageWrap}`}
      >
        <div className={`relative w-full -z-1 ${bgTop}`} />
        <div className={`relative w-full h-full ${bgMain}`} />
      </div>
    </>
  );
};

export default BgImage;
