/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, useState } from 'react';
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { ReactComponent as LogoIcon } from '@/assets/logo-full.svg';
import { ReactComponent as Hamburger } from '@/assets/hamburger.svg';
import { ReactComponent as ChevronDown } from '@/assets/chevron-down.svg';
import { navLink } from './NavBar.module.css';
import flagHR from '@/assets/flags/hr.png';
import flagEN from '@/assets/flags/en.png';
import flagZH from '@/assets/flags/zh.png';

interface NavLinkProps {
  href: string;
  children: ReactElement | ReactElement[] | string | string[];
  onClick: () => void;
}

const NavLink = ({ href, children, onClick }: NavLinkProps): ReactElement => (
  <li className="mb-4 sm:mb-0">
    <Link
      to={href}
      className={`
        text-3xl md:text-2xl uppercase px-2 mx-2 font-serif text-gold-default
        transition-all duration-500 hover:text-gold-brighter ${navLink}`}
      onClick={onClick}
    >
      {children}
    </Link>
  </li>
);

interface Language {
  slug: string;
  title: string;
  flag: any;
}

const languages: Language[] = [
  { slug: 'hr', title: 'Hrvatski', flag: flagHR },
  { slug: 'en', title: 'English', flag: flagEN },
  { slug: 'zh_HANS', title: 'Simplified Chinese', flag: flagZH },
  { slug: 'zh_HANT', title: 'Traditional Chinese', flag: flagZH },
];

const LanguageSwitcher = (): ReactElement => {
  const { originalPath, language } = useI18next();
  const selected = languages.find((lang) => lang.slug === language);

  return (
    <li className="flex mb-4 ml-8 sm:mb-0 group text-3xl md:text-2xl uppercase px-2 mx-2 font-serif text-gold-default transition-all duration-500 hover:text-gold-brighter justify-center items-center flex-wrap">
      <img style={{ height: '1em' }} alt={selected.slug} src={selected.flag} />
      &nbsp;
      {selected.slug.toUpperCase()}
      &nbsp;
      <ChevronDown />
      <ul
        className="w-full justify-center sm:w-auto hidden group-hover:block hover:block sm:absolute bg-gray-darker pt-5 py-3 right-0"
        style={{ top: '56px' }}
      >
        {languages.map((lang) => (
          <li key={lang.slug} className="flex justify-center">
            <Link
              language={lang.slug}
              to={originalPath}
              className="flex items-center px-5 py-2"
            >
              <img style={{ height: '1em' }} alt={lang.slug} src={lang.flag} />
              &nbsp;
              {lang.title}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default function NavBar(): ReactElement {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const smClass = open ? 'flex' : 'hidden';
  const close = () => setOpen(false);
  const toggle = () => setOpen(!open);

  return (
    <nav className="bg-gray-darker flex justify-between absolute w-full z-10 px-10 py-3">
      <Link to="/" aria-label="Povratak na početnu">
        <LogoIcon style={{ height: '50px' }} />
      </Link>
      <ul
        className={`
        items-center
        bg-gray-darker
        left-0 right-0 bottom-0 top-0
        pt-6 lg:pt-0
        flex-col lg:flex-row
        fixed lg:static
        ${smClass} lg:flex`}
        style={{ top: '73px' }}
      >
        <NavLink onClick={close} href="/">
          {t('index.home')}
        </NavLink>
        <NavLink onClick={close} href="/about/">
          {t('about.about')}
        </NavLink>
        <NavLink onClick={close} href="/products/">
          {t('products.products')}
        </NavLink>
        <NavLink onClick={close} href="/awards/">
          {t('awards.awards')}
        </NavLink>
        <NavLink onClick={close} href="/news/">
          {t('news.news')}
        </NavLink>
        <NavLink onClick={close} href="/contact/">
          {t('contact.contact')}
        </NavLink>
        <LanguageSwitcher />
      </ul>
      <button
        type="button"
        className="block lg:hidden w-10 text-gray-light hover:text-white"
        onClick={toggle}
        aria-label="Navigacija"
      >
        <Hamburger />
      </button>
    </nav>
  );
}
